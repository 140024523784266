import React from "react";
import styled from "styled-components";
import Title from "./Title";
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { contactAnimation } from "animation";
import eGilf from "assets/eGift.png";
import xMas from "assets/xmas.png";
import "./button.css";

function Xmas() {
  const [element, controls] = useScroll();
  return (
    <Section id="xmas" ref={element}>
      <Title value="Christmas" />
      <motion.div
        className="contact"
        variants={contactAnimation}
        animate={controls}
        transition={{
          delay: 0.03,
          type: "tween",
          duration: 0.8,
        }}
      >
        <div className="contact__title">
          <img className="img" src={xMas} alt="logo" />
        </div>
        {/* <div>
          <div className="contact__data__form">
            <a target="_blank" href="https://fareharbor.com/embeds/book/caddyshack/items/483648/calendar/2023/08/?flow=305951&fbclid=IwAR1n9c0T76sVG6RePfeDi1Ery-VOscpBQsEqIOmqOei2WgNFESgoYx6hfzY&full-items=yes">
              <button class="glowing-btn">
                <span class="glowing-txt">
                  B<span class="faulty-letter">U</span>Y NOW
                </span>
              </button>
            </a>
          </div>
        </div> */}
      </motion.div>
    </Section>
  );
}

const Section = styled.section`
  overflow: hidden;
  background: #f1f1f1;
  min-height: 100vh;
  width: 100vw;
  margin-bottom: 0rem;

  .contact {
    color: #000;
    margin: 0 18rem;
    // border: 1px solid green;
    &__title {
      margin: 3rem 0 0rem 0;
      .img {
        max-height: 100vh;
      }
      p {
        // text-transform: uppercase;
        color: #000;
      }
      h2 {
        margin-top: 2rem;
        font-size: 2rem;
        color: var(--third-color);
      }
    }
    &__data {
      // border: 1px solid green;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      margin-bottom: 4rem;
      &__description {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        .close {
          padding-bottom: 0rem;
        }

        .open {
          padding-bottom: 1rem;
        }
        .holiday {
          padding-top: 2rem;
        }
        h4 {
          font-size: 1.5rem;
          color: var(--secondary-color);
          // background: transparent;
          text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff, 0 0 40px #fff;
        }

        p {
          text-align: justify;
          margin-top: 20px;
        }
        div {
          p {
            strong {
              text-transform: uppercase;
            }
          }
        }
      }
      &__form {
        display: flex;
        // border: 1px dotted red;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        padding-top: 2rem;
        gap: 2rem;
        .note {
          font-size: 1rem;
          color: #000;
          text-align: justify;
          // border: 1px dotted white;
        }

        h2 {
          font-size: 1.5rem;
          color: #000;
          margin-bottom: 0px;
          text-align: center;
        }
        h4 {
          font-size: 1.5rem;
          color: var(--third-color);
          text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff, 0 0 40px #fff;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
  min-height: 60vh;
  // border: 1px solid red;
    .contact {
      margin: 1rem;
      padding: 0 1rem;
      &__title {
        .img {
          margin-top: 0rem;
          width: 100%;
        }
        margin: 0;
        text-align: center;
        p {
          font-size: 0.8rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
      &__data {
        grid-template-columns: 1fr;
        margin-top: 0rem;
        h4 {
          text-align: center;
          font-size: 1.3rem;
        }
        p {
          text-align: center;
          font-size: 0.8rem;
        }
        &__form {
          gap: 1rem;
          text-align: center;
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1081px) and (max-width: 1366px) {
    .contact {
      color: white;
      margin: 0 10rem;
      &__title {
        margin: 6rem 0 3rem 0;
        .img {
          height: 50vh;
        }
        p {
          // text-transform: uppercase;
          color: white;
        }
        h2 {
          margin-top: 2rem;
          font-size: 2rem;
          color: var(--third-color);
        }
      }
      &__data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        &__description {
          display: flex;
          flex-direction: column;
          gap: 0rem;

          .close {
            padding-bottom: 2rem;
          }

          .open {
            padding-bottom: 2rem;
          }
          .holiday {
            padding-top: 1rem;
          }
          h4 {
            font-size: 1.5rem;
            color: var(--third-color);
          }
          p {
            text-align: justify;
            margin-top: 20px;
          }
          div {
            p {
              strong {
                text-transform: uppercase;
              }
            }
          }
        }
        &__form {
          display: flex;
          border: 1px dotted white;
          flex-direction: column;
          align-items: center;
          padding-top: 2rem;
          gap: 2rem;
          h4 {
            font-size: 1.5rem;
            text-align: center;
            color: var(--third-color);
          }

          p {
            text-align: justify;
          }
        }
      }
    }
  }
`;

export default Xmas;
